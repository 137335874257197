import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";

import { Message } from "types/Message";
import FormControl from "common/components/FormControl/FormControl";
import Button from "common/components/Button/Button";
import { User } from "@sentry/react";
import useList from "common/hooks/useList";
import useOrganization from "common/hooks/useOrganization";
import StickyWrapper from "../StickyWrapper/StickyWrapper";

interface IAddComment {
  onSubmit?: any;
  secondary?: boolean;
  tertiary?: boolean;
  isPrivate?: boolean;
  threadId?: number;
  refresh?: any;
  siteId?: number;
}

function AddComment({
  onSubmit,
  secondary = false,
  tertiary = false,
  isPrivate = false,
  threadId,
  refresh,
  siteId,
}: IAddComment) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { organization } = useOrganization();

  const baseFilters = {
    confirmed: true,
  };

  const { items, setFilter, setFilters } = useList<User>("users", {
    defaultFilters: { ...baseFilters },
    defaultSort: "name:ASC",
    defaultItemsPerPage: 5000,
    populate: [
      "avatar",
      "siteUsers",
      "siteUsers.site",
      "siteUsers.site.mainImage",
      "siteUsers.site.logo",
      "siteUsers.jobPosition",
      "followedSites.mainImage",
      "followedSites.logo",
      "organizations",
    ],
    cachePrefix: `Users_mentions_${organization?.id || "all"}`,
  });

  useEffect(() => {
    setFilters({});
    if (!organization?.id || !siteId) {
      setFilter("role][name", "Administrateur");
    }
    setFilters({
      "organizations][id": organization?.id || false,
      "$or][0][siteUsers][site][id": siteId || false,
      "$or][1][role][name": "Administrateur",
    });
  }, [organization, siteId, setFilter, setFilters]);

  const suggestUsers = useMemo(() => {
    return items?.map((user: User) => ({
      id: user.id,
      display: user.name,
    }));
  }, [items]);

  const onAddComment = useCallback(
    async (message: Message) => {
      if (!onSubmit) {
        return;
      }
      setLoading(true);
      try {
        await onSubmit(message);
        refresh(threadId);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    },
    [onSubmit, refresh, threadId]
  );

  const submitAndClear = async (form: any, handleSubmit: any) => {
    try {
      await handleSubmit();
      form.restart();
    } catch (e: any) {
      return e;
    }
    return true;
  };

  return (
    <StickyWrapper
      isFooter
      background={
        secondary ? "bg-secondary-200" : tertiary ? "bg-blue-100" : "bg-white"
      }
      from={secondary || tertiary ? "from-slate-900/10" : undefined}
      isRelative
    >
      <div
        className={`flex flex-col gap-2 ${
          secondary ? "bg-secondary-200" : tertiary ? "bg-blue-100" : "bg-white"
        }`}
      >
        <Form
          onSubmit={onAddComment}
          className=""
          mutators={{
            setFieldValue: ([field]: any, state: any, utils: any) => {
              utils.changeValue(state, field.field, () => field.value);
            },
          }}
          render={({ handleSubmit, form, values }) => {
            return (
              <>
                <label htmlFor="comment" className="sr-only">
                  {t("threads.yourComment")}
                </label>
                <FormControl
                  name="content"
                  type={isPrivate ? "mentiontextinput" : "textarea"}
                  className={
                    secondary || tertiary ? "text-input-white" : "text-input"
                  }
                  placeholder={
                    isPrivate
                      ? t("threads.yourCommentPlaceholderPrivate")
                      : t("threads.yourCommentPlaceholderPublic")
                  }
                  disabled={loading}
                  options={suggestUsers}
                />
                <div className="flex justify-end gap-2">
                  {!isPrivate ? (
                    <Button
                      type="primary-line"
                      title={t("actions.setCensorAnswser")}
                      onClick={() => {
                        form.mutators.setFieldValue({
                          field: "content",
                          value: t("censorship.censorDefaultMessage"),
                        });
                      }}
                    />
                  ) : null}
                  <FormControl
                    type="file-comment"
                    name="documents"
                    className="flex flex-row-reverse gap-2"
                  />

                  <Button
                    type="primary"
                    icon="IoIosSend"
                    loading={loading}
                    compact
                    onClick={() => submitAndClear(form, handleSubmit)}
                    disabled={!values.documents?.length && !values.content}
                    /* confirm={confirmRefresh}
                  confirmMessage={
                    confirmRefresh ? t("threads.verifyEdit") : undefined
                  }
                  thirdChoiceConfirmAction={
                    thirdChoiceConfirmAction
                      ? () => {
                          thirdChoiceConfirmAction(); // submit post previous edition
                          submitAndClear(form, handleSubmit); // submit new comment
                        }
                      : undefined
                  }
                  confirmMessageYes={t("threads.saveComment")}
                  confirmMessageNo={t("threads.cancelComment")}
                  thirdChoiceConfirmLabel={t("threads.saveAll")}
                  */
                  />
                </div>
              </>
            );
          }}
        />
      </div>
    </StickyWrapper>
  );
}

export default AddComment;
